<template>
  <div
    class="d-flex flex-column justify-center align-center"
    :style="computedHeight"
  >
    <GmapMap
      :center="centerBase"
      ref="mapRef"
      :zoom="15"
      :style="computedHeight"
      :clickable="true"
      :draggable="true"
      :zoomControl="true"
      :options="computedOptions"
      v-show="loadingMap"
    >
      <span v-for="(stop, index) in stateArrayMultiStop" :key="stop.index">
        <GmapMarker
          v-if="Boolean(stop.addressGeocoded)"
          :ref="`stop-${index}`"
          :position="{
            lat: stop.addressGeocoded.geometry.location.lat(),
            lng: stop.addressGeocoded.geometry.location.lng(),
          }"
          :animation="2"
          :draggable="!loadingTable"
          @dragend="handleMultiStopDragEnd($event, index)"
          :icon="handleMultiStopIcons(index)"
        />
      </span>

      <GmapPolyline :path="pathPolyline" :options="routePolyline" />
    </GmapMap>

    <v-progress-circular
      v-if="!loadingMap"
      indeterminate
      :size="64"
      color="primary"
    />
  </div>
</template>

<script>
import { mdiMapMarker, mdiAccount } from "@mdi/js";
import { mapActions, mapState } from "vuex";
import { gmapApi } from "vue2-google-maps";
import GmapCustomMarker from "vue2-gmap-custom-marker";
import nightMap from "@/views/RunningTrips/assets/nightMap";

export default {
  name: "GoogleMap",
  components: {
    GmapCustomMarker,
  },
  props: {
    loadingTable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiMapMarker,
        mdiAccount,
      },
      //Ridery UniAuto
      centerBase: { lat: "", lng: "" },
      centerCabled: { lat: 10.5041952, lng: -66.8858767 },
      loadingMap: false,
      routePolyline: {
        strokeColor: "#EF4370",
        strokeOpacity: 0,
        geodesic: true,
        strokeWeight: 10,
        icons: [
          {
            icon: {
              path: "M 0,0 -1,-2 1,-2 0,0 Z", // Custom arrow path
              rotation: -180, // Rotate the arrow 180 degrees
              fillColor: "#EF4370", // Fill color
              fillOpacity: 1, // Full fill opacity
              strokeOpacity: 1,
              strokeWeight: 2,
              scale: 3.5, // Increase the scale to make it 1.4 times larger
            },
            offset: "0",
            repeat: "20px",
          },
        ],
      },
      stopMarkers: {
        origin: require("../../../RunningTrips/assets/IconOriginMapMap.png"),
        1: require("../../assets/FirstStop.png"),
        2: require("../../assets/Stop2.png"),
        3: require("../../assets/Stop3.png"),
        4: require("../../assets/Stop4.png"),
        5: require("../../assets/Stop5.png"),
        6: require("../../assets/Stop6.png"),
        7: require("../../assets/Stop7.png"),
        8: require("../../assets/Stop8.png"),
        9: require("../../assets/Stop9.png"),
        destination: require("../../../RunningTrips/assets/IconDestinationMapMap.png"),
      },
    };
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setBase",
      "setStateArrayMultiStop",
      "setStateMultiStopChangeInAddress",
    ]),

    handleLocationInitial() {
      const ERROR_PERMISSION_DENIED = 1;
      const ERROR_POSITION_UNAVAILABLE = 2;
      const ERROR_TIMEOUT = 3;

      const success = (position) => {
        if (position) {
          this.centerBase.lat = parseFloat(position.coords.latitude);
          this.centerBase.lng = parseFloat(position.coords.longitude);
          this.setBase(this.centerBase);
        } else {
          this.centerBase = this.centerCabled;
        }
      };

      const handleError = (errorCode, errorMessage, title) => {
        this.$dialog.error({
          text: errorMessage,
          title: title,
          actions: false,
        });
        this.centerBase = this.centerCabled;
        this.setBase(this.centerCabled);
      };

      const error = (err) => {
        switch (err.code) {
          case ERROR_PERMISSION_DENIED:
            handleError(
              ERROR_PERMISSION_DENIED,
              "Para una mejor experiencia, por favor active la geolocalización",
              "Geo Localización Desactivada"
            );
            break;
          case ERROR_POSITION_UNAVAILABLE:
            handleError(
              ERROR_POSITION_UNAVAILABLE,
              "Para una mejor experiencia, por favor intente nuevamente",
              "Geo Localización No Disponible"
            );
            break;
          case ERROR_TIMEOUT:
            handleError(
              ERROR_TIMEOUT,
              "Para una mejor experiencia, por favor intente nuevamente",
              "No fue posible activar Geo Localización"
            );
            break;
          default:
            handleError(
              null,
              "Para una mejor experiencia, por favor intente nuevamente",
              "Error Desconocido"
            );
            break;
        }
      };

      navigator.geolocation.getCurrentPosition(success, error);
    },

    handleMultiStopDragEnd(event, index) {
      console.log(index, event);

      let initialLocation = new this.google.maps.LatLng(
        event.latLng.lat(),
        event.latLng.lng()
      );
      let self = this;
      let geocoder = new this.google.maps.Geocoder();
      geocoder.geocode({ latLng: initialLocation }, async (results, status) => {
        try {
          self.setLoading(true);
          if (status == (await google.maps.GeocoderStatus.OK)) {
            let [address] = results;

            console.log(address);

            if (!address) {
              throw new Error("No address in geocoder");
            }

            let multiStop = this.stateArrayMultiStop;
            multiStop[index].value = address.formatted_address;
            multiStop[index].addressGeocoded = address;

            self.setStateArrayMultiStop(multiStop);

            self.setStateMultiStopChangeInAddress({
              changer: !self.stateMultiStopChangeInAddress.changer,
              indexGiven: index,
            });
            self.handleMultiChange(index);
          }
          self.setLoading(false);
        } catch (error) {
          console.log(error);
        } finally {
          self.setLoading(false);
        }
      });
    },
    async handleMultiChange(index) {
      const marker = {
        lat: this.stateArrayMultiStop[
          index
        ].addressGeocoded.geometry.location.lat(),
        lng: this.stateArrayMultiStop[
          index
        ].addressGeocoded.geometry.location.lng(),
      };
      console.log(marker);
      this.$refs.mapRef.panTo(marker);

      await new Promise((r) => setTimeout(r, 1200));
      let bounding = new this.google.maps.LatLngBounds();
      this.stateArrayMultiStop.forEach((stop) => {
        if (
          stop.addressGeocoded !== null &&
          typeof stop.addressGeocoded === "object" &&
          Boolean(stop.addressGeocoded.geometry)
        ) {
          bounding.extend({
            lat: stop.addressGeocoded.geometry.location.lat(),
            lng: stop.addressGeocoded.geometry.location.lng(),
          });
        }
      });
      this.$refs.mapRef.fitBounds(bounding);
    },
    handleMultiStopIcons(index) {
      if (index === 0) {
        return this.stopMarkers["origin"];
      }
      if (index === this.stateArrayMultiStop.length - 1) {
        return this.stopMarkers["destination"];
      }
      return this.stopMarkers[index];
    },
  },
  computed: {
    ...mapState([
      "iconsDraggable",
      "stateMultiStop",
      "stateMultiStopOrigin",
      "stateMultiStopStop1",
      "stateMultiStopGenericStop",
      "stateArrayMultiStop",
      "stateMultiStopChangeInAddress",
    ]),
    google: gmapApi,

    computedOptions() {
      if (this.$vuetify.theme.isDark) {
        return {
          streetViewControl: false,
          styles: nightMap,
        };
      }
      if (!this.$vuetify.theme.isDark) {
        return { streetViewControl: false, styles: [] };
      }
    },
    computedHeight() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return "width: 100%; height: 39.5rem";
      } else {
        return "width: 100%; height: 39.5rem";
      }
    },
    pathPolyline() {
      const arrayOfGeocodedStops = this.stateArrayMultiStop.filter(
        (stop) =>
          stop.addressGeocoded && typeof stop.addressGeocoded === "object"
      );

      const coordsArray = arrayOfGeocodedStops.map((coord) => ({
        lat: coord.addressGeocoded.geometry.location.lat(),
        lng: coord.addressGeocoded.geometry.location.lng(),
      }));

      return coordsArray;
    },
  },
  watch: {
    stateMultiStopChangeInAddress: function () {
      const { indexGiven } = this.stateMultiStopChangeInAddress;
      this.handleMultiChange(indexGiven);
    },
  },
  mounted() {
    if (this.$refs.mapRef) {
      this.$refs.mapRef.$mapPromise.then(() => {
        this.loadingMap = !this.loadingMap;
      });
    }
  },
  created() {
    if (!this.loadingMap) {
      this.handleLocationInitial();
    }
  },
};
</script>
