<template>
  <div>
    <v-data-table
      :headers="computedHeaders"
      :disable-sort="true"
      :items="items"
      class="dataTable elevation-1"
      calculate-widths
      hide-default-footer
      disable-pagination
      fixed-header
      mobile-breakpoint="600"
      :dense="true"
    >
      <template v-slot:header.costStop="{ header }">
        <v-tooltip top :disabled="showPage">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">
              {{ header.text }}{{ !showPage ? " *" : "" }}</span
            >
          </template>
          <span>{{ $t("variable_tariffs") }}</span>
        </v-tooltip>
      </template>
      <template #item.stops="{ value }">
        <span>
          <td>
            <v-img
              :src="!parcel ? require(`@/views/Multistop/assets/Stop${value}.png`) : require(`@/views/ParcelMultistop/assets/Stop${value}.png`)"
              max-width="42"
            ></v-img>
          </td>
        </span>
      </template>
      <template #item.date="{ value }">
        <td>
          <span>
            {{ value | moment("DD-MM-YYYY h:mm a") }}
          </span>
        </td>
      </template>
      <template #item.serviceType="{ value }">
        <span
          class="d-flex flex-column justify-center align-center mt-2"
          v-if="value"
        >
          <v-tooltip
            top
            content-class="bs-tooltip-top arrow"
            :max-width="300"
            bottom
          >
            <template #activator="{ on, attrs }">
              <span
                v-bind="attrs"
                v-on="on"
                class="d-flex flex-column justify-center align-center"
              >
                <v-img :src="`${bucketUrl}${value.image}`" max-width="60">
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row> </template
                ></v-img>
                <span class="fontUrbanist font-weight-bold">{{
                  value.type
                }}</span>
              </span>
            </template>
            <span class="d-flex flex-row justify-center align-center">
              <v-icon
                x-small
                :style="!$vuetify.theme.isDark && 'color: #ffffff !important;'"
              >
                {{ icons.mdiAccount }}
              </v-icon>
              <span class="ml-2">
                {{ value.capacity }}
              </span>
              <span class="ml-2"> | </span>
              <v-icon
                x-small
                class="ml-2"
                :style="!$vuetify.theme.isDark && 'color: #ffffff !important;'"
              >
                {{ icons.mdiBagSuitcase }}
              </v-icon>
              <span class="ml-2">
                {{ value.baggage }}
              </span>
            </span>
          </v-tooltip>
        </span>
        <span v-else>
          <!-- <v-progress-circular indeterminate /> -->
        </span>
      </template>

      <template #item.provider_name="{ item }">
        <span v-if="!item.trip.provider_first_name">{{ $t("No driver") }}</span>
        <a
          v-else
          target="_blank"
          :href="
            $route.name !== 'TripInfo' && `/provider/${item.trip.provider_id}`
          "
        >
          {{
            item.trip.provider_first_name + " " + item.trip.provider_last_name
          }}
        </a>
      </template>

      <template #item.user_name="{ item }">
        <td>
          {{ item.user_name }}
          <br />
          <a :href="`tel:${item.trip.user.phone}`">
            {{ item.trip.user.phone }}
          </a>
        </td>
      </template>

      <template #item.status="{ item }">
        <v-chip
          v-if="
            item.trip.trip_status === 'Waiting' &&
            !item.trip.server_start_time_for_schedule
          "
          class="v-chip-light-bg error--text"
          color="accent"
        >
          {{ $t(item.trip.trip_status) }}</v-chip
        >
        <v-chip
          v-if="
            item.trip.trip_status === 'Waiting' &&
            item.trip.server_start_time_for_schedule
          "
          class="v-chip-light-bg primary--text"
          color="primary"
        >
          {{ $t("Scheduled") }}</v-chip
        >
        <v-chip
          v-else-if="item.trip.trip_status === 'Accepted'"
          class="v-chip-light-bg warning--text"
          color="warning"
        >
          {{ $t(item.trip.trip_status) }}</v-chip
        >
        <v-chip
          v-else-if="item.trip.trip_status === 'Coming'"
          class="v-chip-light-bg warning--text"
          color="warning"
        >
          {{ $t(item.trip.trip_status) }}</v-chip
        >
        <v-chip
          v-else-if="item.trip.trip_status === 'Arrived'"
          class="v-chip-light-bg success--text"
          color="success"
        >
          {{ $t(item.trip.trip_status) }}</v-chip
        >
        <v-chip
          v-else-if="item.trip.trip_status === 'Started'"
          class="v-chip-light-bg success--text"
          color="success"
        >
          {{ $t(item.trip.trip_status) }}</v-chip
        >
        <!-- <v-chip v-else>
          {{ $t(item.trip) }}
        </v-chip> -->
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiRefresh,
  mdiMapMarker,
  mdiClose,
  mdiAccount,
  mdiBagSuitcase,
} from "@mdi/js";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    headers: {
      required: true,
    },
    items: {
      required: true,
    },
    showPage: {
      type: Boolean,
      default: false,
    },
    parcel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMapMarker,
        mdiClose,
        mdiAccount,
        mdiBagSuitcase,
      },
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
    };
  },
  computed: {
    ...mapState(["stateMultiStop"]),

    computedHeaders() {
      let headers = this.headers;
      headers.map((head) => (head.text = this.$t(head.text)));
      return headers;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
