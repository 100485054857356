var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"dataTable elevation-1",attrs:{"headers":_vm.computedHeaders,"disable-sort":true,"items":_vm.items,"calculate-widths":"","hide-default-footer":"","disable-pagination":"","fixed-header":"","mobile-breakpoint":"600","dense":true},scopedSlots:_vm._u([{key:"header.costStop",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":"","disabled":_vm.showPage},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(header.text)+_vm._s(!_vm.showPage ? " *" : ""))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("variable_tariffs")))])])]}},{key:"item.stops",fn:function(ref){
var value = ref.value;
return [_c('span',[_c('td',[_c('v-img',{attrs:{"src":!_vm.parcel ? require(("@/views/Multistop/assets/Stop" + value + ".png")) : require(("@/views/ParcelMultistop/assets/Stop" + value + ".png")),"max-width":"42"}})],1)])]}},{key:"item.date",fn:function(ref){
var value = ref.value;
return [_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(value,"DD-MM-YYYY h:mm a"))+" ")])])]}},{key:"item.serviceType",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{staticClass:"d-flex flex-column justify-center align-center mt-2"},[_c('v-tooltip',{attrs:{"top":"","content-class":"bs-tooltip-top arrow","max-width":300,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-flex flex-column justify-center align-center"},'span',attrs,false),on),[_c('v-img',{attrs:{"src":("" + _vm.bucketUrl + (value.image)),"max-width":"60"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('span',{staticClass:"fontUrbanist font-weight-bold"},[_vm._v(_vm._s(value.type))])],1)]}}],null,true)},[_c('span',{staticClass:"d-flex flex-row justify-center align-center"},[_c('v-icon',{style:(!_vm.$vuetify.theme.isDark && 'color: #ffffff !important;'),attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccount)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(value.capacity)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" | ")]),_c('v-icon',{staticClass:"ml-2",style:(!_vm.$vuetify.theme.isDark && 'color: #ffffff !important;'),attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiBagSuitcase)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(value.baggage)+" ")])],1)])],1):_c('span')]}},{key:"item.provider_name",fn:function(ref){
var item = ref.item;
return [(!item.trip.provider_first_name)?_c('span',[_vm._v(_vm._s(_vm.$t("No driver")))]):_c('a',{attrs:{"target":"_blank","href":_vm.$route.name !== 'TripInfo' && ("/provider/" + (item.trip.provider_id))}},[_vm._v(" "+_vm._s(item.trip.provider_first_name + " " + item.trip.provider_last_name)+" ")])]}},{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(" "+_vm._s(item.user_name)+" "),_c('br'),_c('a',{attrs:{"href":("tel:" + (item.trip.user.phone))}},[_vm._v(" "+_vm._s(item.trip.user.phone)+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(
          item.trip.trip_status === 'Waiting' &&
          !item.trip.server_start_time_for_schedule
        )?_c('v-chip',{staticClass:"v-chip-light-bg error--text",attrs:{"color":"accent"}},[_vm._v(" "+_vm._s(_vm.$t(item.trip.trip_status)))]):_vm._e(),(
          item.trip.trip_status === 'Waiting' &&
          item.trip.server_start_time_for_schedule
        )?_c('v-chip',{staticClass:"v-chip-light-bg primary--text",attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("Scheduled")))]):(item.trip.trip_status === 'Accepted')?_c('v-chip',{staticClass:"v-chip-light-bg warning--text",attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.$t(item.trip.trip_status)))]):(item.trip.trip_status === 'Coming')?_c('v-chip',{staticClass:"v-chip-light-bg warning--text",attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.$t(item.trip.trip_status)))]):(item.trip.trip_status === 'Arrived')?_c('v-chip',{staticClass:"v-chip-light-bg success--text",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t(item.trip.trip_status)))]):(item.trip.trip_status === 'Started')?_c('v-chip',{staticClass:"v-chip-light-bg success--text",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.$t(item.trip.trip_status)))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }