var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"dataTable elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.computedHeaders,"disable-sort":true,"items":_vm.items,"calculate-widths":"","hide-default-footer":"","disable-pagination":"","fixed-header":"","mobile-breakpoint":"600","dense":true,"single-expand":false,"item-key":"stopNumber","show-expand":_vm.parcel,"expanded":_vm.$route.name === 'TripInfo' && !_vm.$vuetify.breakpoint.mdAndUp && _vm.items || []},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-8",attrs:{"colspan":headers.length}},[(item.shipmentDetails)?_c('v-row',{staticClass:"d-flex"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between",attrs:{"cols":"12"}},[_c('h2',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t("parcel_details")))]),_c('a',{attrs:{"href":("https://api.whatsapp.com/send?phone=+58" + (item.shipmentDetails.responsible_phone) + "&text=Hola, %20te%20estoy%20enviando%20%20por%20Ridery"),"target":"_blank"}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiWhatsapp))])],1)])],1),_c('v-list',[_c('v-list-item',{attrs:{"three-line":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"5","cols":"12"}},[_c('v-list-item-content',{style:(_vm.$vuetify.breakpoint.mdAndUp
                            ? 'border-right: solid 1px #3bd4ae'
                            : 'border-bottom: solid 1px #3bd4ae; padding-bottom: 30px;')},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("parcel_details_table")))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(((item.shipmentDetails.responsible_first_name) + " " + (item.shipmentDetails.responsible_last_name)))+" ")]),_c('v-list-item-subtitle',[_c('a',{attrs:{"href":("tel:+58" + (item.shipmentDetails.responsible_phone))}},[_vm._v(" "+_vm._s(item.shipmentDetails.responsible_phone))])])],1)],1),_c('v-col',{attrs:{"md":"7","cols":"12"}},[_c('v-list-item-content',{staticClass:"pl-md-4"},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("parcel_details")))]),_c('p',{staticClass:"text-justify",staticStyle:{"color":"rgba(94, 86, 105, 0.6)"}},[_vm._v(" "+_vm._s(item.shipmentDetails.package_description)+" ")])],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)]}},{key:"header.stopOrigin",fn:function(ref){
                            var header = ref.header;
return [_c('span',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{staticStyle:{"max-width":"20px"},attrs:{"src":require( !_vm.parcel ? '@/views/RunningTrips/assets/IconOriginMap.png' : '@/views/Parcel/assets/IconOriginMap.png')}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"header.stopDestination",fn:function(ref){
                            var header = ref.header;
return [_c('span',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{staticStyle:{"max-width":"20px"},attrs:{"src":require(!_vm.parcel ? '@/views/RunningTrips/assets/IconDestinationMap.png' : '@/views/Parcel/assets/IconDestinationMap.png')}}),_vm._v(" "+_vm._s(header.text)+" ")],1)]}},{key:"header.costTotal",fn:function(ref){
                            var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":"","disabled":_vm.showPage},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(header.text)+_vm._s(!_vm.showPage ? " *" : ""))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("variable_tariffs")))])])]}},{key:"item.stopNumber",fn:function(ref){
                            var value = ref.value;
return [_c('span',[_c('td',[_c('v-img',{attrs:{"src":!_vm.parcel ? require(("@/views/Multistop/assets/Stop" + value + ".png")) : require(("@/views/ParcelMultistop/assets/Stop" + value + ".png")),"max-width":"42"}})],1)])]}},{key:"item.actions",fn:function(ref){
                            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","disabled":item.stopNumber + 1 !== _vm.stateMultiStop.length || _vm.loading},on:{"click":function($event){return _vm.handleRemove(item.stopNumber)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Delete"))+" "+_vm._s(_vm.$t("multistop_stop"))+" # "+_vm._s(item.stopNumber))])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }