var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-start justify-center",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("multistop_list"))+" ")]),_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text rounded-0",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("multistop_list_tooltip"))+" ")])])],1)])],1),_c('v-row',{attrs:{"dense":""}},[(
          _vm.computedAllGeocodedStops.length >= 2 &&
          Boolean(_vm.stateServiceTypeOrigin)
        )?_c('v-col',{attrs:{"cols":"12"}},[_c('DataTable',{attrs:{"headers":_vm.table.headers,"loading":_vm.table.tableLoading,"items":_vm.table.items}})],1):_c('v-col',{staticClass:"d-flex flex-row justify-center align-center",attrs:{"cols":"12"}},[(!_vm.$vuetify.theme.isDark)?_c('v-img',{attrs:{"src":require("@/views/Multistop/assets/placerNewLight.png"),"max-width":"300"}}):_c('v-img',{attrs:{"src":require("@/views/Multistop/assets/placerNewDark.png"),"max-width":"300"}})],1)],1),_c('br'),_c('br'),(
        _vm.computedAllGeocodedStops.length >= 3 &&
        Boolean(_vm.stateServiceTypeOrigin)
      )?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-start justify-center",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("multistop_total_resume"))+" ")]),_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text rounded-0",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}],null,false,2181757754)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("multistop_total_resume_tooltip"))+" ")])])],1)])],1):_vm._e(),(
        _vm.computedAllGeocodedStops.length >= 3 &&
        Boolean(_vm.stateServiceTypeOrigin)
      )?_c('v-row',{attrs:{"dense":""}},[(!_vm.table.tableLoading)?_c('v-col',{attrs:{"cols":"12"}},[_c('DataTableTotal',{attrs:{"headers":_vm.tableTotal.headers,"items":_vm.computedTableTotal}}),_c('br'),_c('v-row',{staticClass:"d-flex flex-row justify-center",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":function($event){return _vm.downloadInvoice()}}},[_vm._v(" "+_vm._s(_vm.$t("Download Quote"))+" ")])],1),_c('v-col',{attrs:{"cols":"6","md":"4"}},[_c('v-btn',{attrs:{"color":"success","block":""},on:{"click":function($event){return _vm.handleMultiStopQuote()}}},[_vm._v(" "+_vm._s(_vm.$t("multistop_total_quote"))+" ")])],1)],1)],1):_c('v-col',{staticClass:"d-flex flex-row justify-center align-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }