var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-start justify-center",attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"d-flex flex-row justify-start align-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t("multistop_add"))+" ")]),_c('v-tooltip',{attrs:{"top":"","max-width":300},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-6 color-gray-text rounded-0",attrs:{"medium":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("multistop_add_tooltip"))+" ")])])],1)])],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"d-flex flex-column justify-space-between",style:(_vm.$vuetify.breakpoint.mdAndUp &&
          'min-height: 40rem; max-height: 40rem; overflow: auto;'),attrs:{"spellcheck":"false","disabled":_vm.loadingTable}},[_c('div',{},[_c('draggable',{staticClass:"mt-2",attrs:{"options":{ handle: '.drag-handle' }},on:{"start":function($event){_vm.multiStop.drag = true},"end":function($event){return _vm.handleDragEnd()}},model:{value:(_vm.multiStop.stops),callback:function ($$v) {_vm.$set(_vm.multiStop, "stops", $$v)},expression:"multiStop.stops"}},_vm._l((_vm.multiStop.stops),function(stop,index){return _c('v-row',{key:stop.index,staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('ValidationProvider',{attrs:{"name":index === 0
                      ? 'Origen'
                      : index !== _vm.multiStop.length - 1
                      ? ("Parada" + (index + 1))
                      : 'Destino',"rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('vuetify-google-autocomplete',{ref:("stop-" + index),refInFor:true,attrs:{"id":("stop-" + index),"classname":"form-control","label":index === 0
                        ? _vm.ES
                          ? 'Origen'
                          : 'Origin'
                        : index + 1 !== _vm.multiStop.stops.length
                        ? _vm.ES
                          ? ("Parada" + index)
                          : ("Stop" + index)
                        : _vm.ES
                        ? 'Destino'
                        : 'Destiny',"country":"ve","outlined":"","dense":"","error-messages":errors[0] && [_vm.$t('Obligatory field')],"enable-geolocation":true,"types":"","disabled":_vm.handleAutocompleteOrder(index)},on:{"placechanged":_vm.changeMultipStop},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('div',[_c('v-img',{attrs:{"src":_vm.handleMultiStopIcon(index),"max-width":"30"}})],1)]},proxy:true},{key:"append-outer",fn:function(){return [_c('v-btn',{staticClass:"drag-handle",attrs:{"icon":"","disabled":!_vm.computedAllStopsHaveAddressGeocoded}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDragHorizontalVariant)+" ")])],1),_c('v-btn',{attrs:{"icon":"","disabled":_vm.multiStop.stops.length <= 3},on:{"click":function($event){return _vm.handleRemoveMultiStop(index)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose)+" ")])],1)]},proxy:true}],null,true),model:{value:(stop.value),callback:function ($$v) {_vm.$set(stop, "value", $$v)},expression:"stop.value"}})]}}],null,true)})],1)],1)}),1),_c('div',[_c('v-row',{staticClass:"ma-0 pa-0 mb-2",attrs:{"dense":""}},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('v-btn',{attrs:{"block":"","color":"primary","outlined":"","disabled":_vm.multiStop.stops.length === 10 ||
                    !_vm.computedAllStopsHaveAddressGeocoded},on:{"click":function($event){return _vm.handleAddStopToMultiStop()}}},[_vm._v(" "+_vm._s(_vm.ES ? "Agregar parada" : "Add stop")+" ")])],1)],1)],1)],1),_c('div',[_c('v-divider'),_c('v-row',{staticClass:"ma-0 pa-0 mt-4",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('trip description'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('trip description'),"outlined":"","maxlength":"60","dense":"","error-messages":errors[0]},model:{value:(_vm.tripComment),callback:function ($$v) {_vm.tripComment=$$v},expression:"tripComment"}})]}}],null,true)})],1)],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Date and Time'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-datetime-picker',{attrs:{"label":_vm.$t('Date and Time'),"dense":"","clearText":"Limpiar","okText":"Aceptar","text-field-props":_vm.dateTimePickerProps.textFieldProps,"date-picker-props":_vm.dateTimePickerProps.dateProps,"time-picker-props":_vm.dateTimePickerProps.timeProps,"time-format":"h:mm a","dateFormat":"dd-MM-yyyy","error-messages":errors[0] && [_vm.$t('Obligatory field')],"disabled":_vm.loadingTable},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
                    var parent = ref.parent;
return [_c('span',{staticClass:"mt-5 d-flex flex-row justify-center",staticStyle:{"width":"100%"}},[_c('v-btn',{staticClass:"ml-3",attrs:{"color":"primary","outlined":""},nativeOn:{"click":function($event){return parent.clearHandler.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("Clear"))+" ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":parent.okHandler}},[_vm._v(_vm._s(_vm.$t("Accept"))+" ")])],1)]}}],null,true),model:{value:(_vm.dateTimePickerProps.datetime),callback:function ($$v) {_vm.$set(_vm.dateTimePickerProps, "datetime", $$v)},expression:"dateTimePickerProps.datetime"}},[_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiCalendar))])],1),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClock))])],1)],2)]}}],null,true)})],1)],1),_c('v-row',{staticClass:"ma-0 pa-0",attrs:{"dense":""}},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('ValidationProvider',{attrs:{"name":_vm.$t('Service type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{staticClass:"d-flex flex-row align-baseline",attrs:{"items":_vm.multiStop.serviceTypeOptions,"label":_vm.$t('Service type'),"outlined":"","dense":"","error-messages":errors[0] && [_vm.$t('Obligatory field')],"item-text":"type","item-value":"_id","menu-props":{ contentClass: 'list-style' },"disabled":!_vm.multiStop.stops[0].value || _vm.loadingTable},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"\n                        d-flex\n                        flex-row\n                        justify-space-between\n                        font-weight-bold\n                      "},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('v-img',{attrs:{"src":("" + _vm.bucketUrl + (item.image)),"max-width":"45"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('h4',{staticClass:"fontUrbanist ml-2"},[_vm._v(" "+_vm._s(item.type)+" ")]),_c('span',{staticClass:"d-flex align-center ml-2"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccount)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.capacity)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("|")]),_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiBagSuitcase)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.baggage)+" ")])],1)],1)])]}},{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('v-img',{attrs:{"src":("" + _vm.bucketUrl + (item.image)),"max-width":"60"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"fontUrbanist"},[_vm._v(" "+_vm._s(item.type)+" ")]),_c('v-list-item-subtitle',[_c('span',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAccount)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.capacity)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("|")]),_c('v-icon',{staticClass:"ml-1",attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiBagSuitcase)+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.baggage)+" ")])],1)])],1)]}}],null,true),model:{value:(_vm.multiStop.serviceType),callback:function ($$v) {_vm.$set(_vm.multiStop, "serviceType", $$v)},expression:"multiStop.serviceType"}})]}}],null,true)})],1)],1)],1)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }