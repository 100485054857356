import axios from "@/plugins/axios";

export async function getRouteDistanceAndDurationOpenStreet(
  origin,
  destination
) {
  const start = `${origin.lat},${origin.lng}`;
  const end = `${destination.lat},${destination.lng}`;
  const apiUrl = `https://api.ridery.app/osrm_ios/${start};${end}`;
  try {
    const { data } = await axios.get(apiUrl);
    if (!data?.code === "Ok") {
      throw new Error("Error in osrm.ridery.app/route/v1/driving");
    }
    const { routes } = data;
    const distance = parseFloat(routes[0].distance);
    const duration = parseFloat(routes[0].duration);
    return { distance, duration };
  } catch (error) {
    throw new Error(error.message);
  }
}
export async function getRouteDistanceAndDurationGoogleMaps(
  origin,
  destination,
  google
) {
  const service = new google.maps.DistanceMatrixService();
  const response = await new Promise((resolve, reject) => {
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [destination],
        travelMode: "DRIVING",
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      (results, status) => {
        if (status === "OK") {
          let distance = parseFloat(results.rows[0].elements[0].distance.value);
          let duration = parseFloat(results.rows[0].elements[0].duration.value);
          if (!!distance && !!duration) {
            resolve({ distance, duration });
          } else {
            reject(new Error("Error: distance or duration is not defined"));
          }
        } else {
          reject(new Error(`Error getting distance: ${status}`));
        }
      }
    );
  });
  return response;
}
