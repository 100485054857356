<template>
  <div>
    <v-card class="pa-5">
      <v-row dense>
        <v-col
          cols="12"
          md="12"
          class="d-flex flex-row justify-md-start justify-center"
        >
          <span class="d-flex flex-row justify-start align-center">
            <h2>
              {{ $t("Multistop") }}
            </h2>
            <v-tooltip top :max-width="300">
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  medium
                  v-on="on"
                  class="ml-6 color-gray-text rounded-0"
                >
                  {{ icons.mdiInformationOutline }}
                </v-icon>
              </template>
              <span>
                <!-- <ul> -->
                <!-- <li> -->
                {{
                  $t(
                    "multistop_new_map_tooltip"
                  )
                }}
                <!-- </li> -->
                <!-- </ul> -->
              </span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <GoogleMap :loadingTable="loadingTable"/>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mdiInformationOutline, mdiRefresh } from "@mdi/js";
import GoogleMap from "./GoogleMap.vue";

export default {
  components: {
    GoogleMap,
  },
  props: {
    loadingTable: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
      },
    };
  },
};
</script>
<style lang="scss" scoped></style>
