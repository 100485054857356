<template>
  <div>
    <v-row dense>
      <v-col md="8" cols="12"> <MapPart :loadingTable="loadingTable" /> </v-col>
      <v-col md="4" cols="12">
        <FormPart :loadingTable="loadingTable" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col md="12" cols="12">
        <MultiStopTable
          @loadingTable="handleLoadingTable"
          @updateQuote="updateQuote($event)"
          @downloadQuotePDF="downloadInvoice()"
        />
      </v-col>
    </v-row>
    <!-- Component used only for rendering the view to download a quote -->
    <QuoteInvoice
      ref="invoiceComponent"
      style="display: none; position: absolute; right: 10000px"
      :quote="quote"
    />
  </div>
</template>
<script>
import MapPart from "./parts/MapPart.vue";
import FormPart from "./parts/FormPart.vue";
import MultiStopTable from "./parts/MultiStopTable.vue";
import QuoteInvoice from "@/views/RunningTrips/components/Invoice/QuoteInvoice.vue";
import { mapActions, mapState } from "vuex";
import html2pdf from "html2pdf.js";
import axios from "@/plugins/axios";

export default {
  components: {
    MapPart,
    FormPart,
    MultiStopTable,
    QuoteInvoice,
  },
  data() {
    return {
      quote: {},
      loadingTable: false,
    };
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setServiceTypeOrigin",
      "setDateTime",
      "setStateArrayMultiStop",
      "setStateTripComment",
    ]),

    handleLoadingTable(status) {
      this.loadingTable = status;
    },
    updateQuote(quote) {
      this.quote = quote;
    },
    downloadInvoice() {
      const isDarkMode = this.$vuetify.theme.dark;
      const invoiceComponent = this.$refs.invoiceComponent;
      this.setLoading(true);
      try {
        if (isDarkMode) {
          this.$vuetify.theme.dark = false;
        }

        setTimeout(async () => {
          const element = invoiceComponent.$refs.carInvoice;
          element.className = "col-12 fontUrbanist";
          const images = element.querySelectorAll("img");
          for (const img of images) {
            if (!img.src.startsWith("https://riderys3bucket.s3")) {
              continue;
            }
            try {
              const response = await axios.get(
                `${process.env.VUE_APP_STAGING}/corporate/proxy?url=${img.src}`,
                {
                  responseType: "blob",
                }
              );
              const blob = response.data;
              const reader = new FileReader();
              reader.onloadend = () => {
                img.src = reader.result;
              };
              reader.readAsDataURL(blob);
            } catch (error) {
              console.error("Error fetching image:", error);
            }
          }

          // Wait for all images to be converted to base64
          await new Promise((resolve) => setTimeout(resolve, 1000));

          const opt = {
            margin: 5,
            filename: `Ridery Corporate Panel - ${this.$t(
              "Service Quote"
            )}.pdf`,
            image: { type: "jpeg", quality: 0.95 },
            html2canvas: { scale: 2, useCORS: true },
            jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
          };

          invoiceComponent.$el.style.display = "block";
          html2pdf()
            .set(opt)
            .from(element)
            .save()
            .finally(() => {
              this.setLoading(false);
              if (isDarkMode) {
                this.$vuetify.theme.dark = true;
              }
            });
        }, 10);
      } catch (error) {
        this.setLoading(false);
        if (isDarkMode) {
          this.$vuetify.theme.dark = true;
        }
      } finally {
        invoiceComponent.$el.style.display = "none";
      }
    },
  },
  computed: {},
  created() {
    this.setServiceTypeOrigin(null);
    this.setDateTime(null);
    this.setStateArrayMultiStop([]);
    setStateTripComment(null);
  },
};
</script>
<style lang="scss"></style>
